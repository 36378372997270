const {
  INVOICE_SENT_LOADING,
  GET_SENT_INVOICE_SUCCESS,
  GET_SENT_INVOICE_FAILURE,
} = require("./actionTypes")

const initial_state = {
  sent_invoices: [],
  loading: false,
  err: {},
}

const sent_invoices = (state = initial_state, action) => {
  switch (action.type) {
    case INVOICE_SENT_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_SENT_INVOICE_SUCCESS:
      return {
        ...state,
        sent_invoices: action.payload,
        loading: false,
        err: {},
      }
    case GET_SENT_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        sent_invoices: [],
        err: action.payload,
      }
    default:
      return state
  }
}

export default sent_invoices
