import { combineReducers } from "redux"

// Front
import company_preference from "../redux/companyPreference/reducer"
import contacts from "../redux/contacts/reducer"
import sendInvitation from "../redux/contacts/SendInvitation/reducer"
import recievedFiles from "../redux/FileRecieved/reducer"
import files from "../redux/FileSent/reducer"
import draft_invoices from "../redux/invoice/draft/reducer"
import recieve_invoices from "../redux/invoice/recieve/reducer"
import sent_invoices from "../redux/invoice/send/reducer"
import user from "../redux/user/reducer"
import updateUser from "../redux/user/updateUser/reducer"
import subscriptionDetails from "../redux/userSubcriptionDetails/reducer"
import Layout from "./layout/reducer"

const rootReducer = combineReducers({
  Layout,
  files,
  recievedFiles,
  user,
  contacts,
  sent_invoices,
  recieve_invoices,
  draft_invoices,
  company_preference,
  sendInvitation,
  updateUser,
  subscriptionDetails,
})

export default rootReducer
