import { FlashOffRounded } from "@material-ui/icons"
import {
  INVITATION_LOADING,
  INVITATION_FAILURE,
  INVITATION_SUCCESS,
  CLEAR_ERROR,
  CLEAR_STATE
} from "./actionType"

const initial_state = {
  success: false,
  loading: false,
  err: null,
}

const sendInvitation = (state = initial_state, action) => {
  switch (action.type) {
    case INVITATION_LOADING:
      return {
        ...state,
        loading: true,
      }
    case INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        err: null,
      }
    case INVITATION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        err: action.payload,
      }
    case CLEAR_ERROR:
      return {
        ...state,
     
        err: null,
      }
      case CLEAR_STATE:
        return {
          ...state, err:null,
          loading: false,
          success: false,

        }
    default:
      return state
  }
}

export default sendInvitation
