import {
  DRAFT_INVOICE_LOADING,
  GET_DRAFT_INVOICE_SUCCESS,
  GET_DRAFT_INVOICE_FAILURE,
} from "./actionTypes"

const initial_state = {
  draft_invoices: [],
  loading: false,
  err: {},
}

const draft_invoices = (state = initial_state, action) => {
  switch (action.type) {
    case DRAFT_INVOICE_LOADING:
      return {
        ...state,
        loading: true,
      }

    case GET_DRAFT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        err: {},
        draft_invoices: action.payload,
      }
    case GET_DRAFT_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        draft_invoices: [],
        err: action.payload,
      }
    default:
      return state
  }
}

export default draft_invoices
