const {
  INVOICE_RECIEVE_LOADING,
  GET_RECIEVE_INVOICE_SUCCESS,
  GET_RECIEVE_INVOICE_FAILURE,
} = require("./actionTypes")

const initial_state = {
  recieve_invoices: [],
  loading: false,
  err: {},
}

const recieve_invoices = (state = initial_state, action) => {
  switch (action.type) {
    case INVOICE_RECIEVE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_RECIEVE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        err: {},
        recieve_invoices: action.payload,
      }
    case GET_RECIEVE_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        recieve_invoices: [],
        err: action.payload,
      }
    default:
      return state
  }
}

export default recieve_invoices
