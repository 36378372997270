const {
  USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} = require("redux/user/actionTypes")

const initial_state = {
  user: null,
  loading: false,
  err: {},
}

const user = (state = initial_state, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        err: {},
      }
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        err: action.payload,
      }
    default:
      return state
  }
}

export default user
