import {
  UPDATE_USER_ERROR,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  CLEAR_STATE
} from "./actionTypes"

const initial_state = {
  success: false,
  loading: false,
  err: null,
}

 const updateUser = (state = initial_state, action) => {
  switch (action.type) {
    case UPDATE_USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        err: action.payload,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        err: null,
      }
    case CLEAR_STATE:
      return {
        ...state,
        loading: false,
        success: false,
        err: null,
      }

    default:
          return  state
  }
}
export default updateUser