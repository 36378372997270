import {
  COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
} from "./actionTypes"

const initial_state = {
  companies: [],
  loading: false,
  err: {},
}

const company_preference = (state = initial_state, action) => {
  switch (action.type) {
    case COMPANY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload,
        err: {},
      }
    case GET_COMPANY_FAILURE:
      console.log(action.payload)
      return {
        ...state,
        loading: false,
        companies: [],
        err: action.payload,
      }
    default:
      return state
  }
}

export default company_preference
