import { CircularProgress } from "@material-ui/core"
import React from "react"
import "./loader.css"

function Loader() {
  return (
    <React.Fragment>
      <div className="loader">
        <CircularProgress color="inherit" />
      </div>
    </React.Fragment>
  )
}

export default Loader
