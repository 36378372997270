import Loader from "components/loader/loader"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
// import App from "./App"
import * as serviceWorker from "./serviceWorker"
import store from "./store"
const App = React.lazy(() => import("./App"))

const app = (
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
