import {
    SUBSCRIPTION_LOADING,
   GET_SUBSCRIPTION_SUCCESS,
   GET_SUBSCRIPTION_FAILURE,
  } from "./actionTypes"
  
  const initial_state = {
    subscriptionDetails: [],
    loading: false,
    err: {},
  }
  
  const reducer = (state = initial_state, action) => {
    switch (action.type) {
      case SUBSCRIPTION_LOADING:
        return {
          ...state,
          loading: true,
        }
      case GET_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          loading: false,
          subscriptionDetails: action.payload,
          err: {},
        }
      case GET_SUBSCRIPTION_FAILURE:
        return {
          ...state,
          loading: false,
          subscriptionDetails: [],
          err: action.payload,
        }
      default:
        return state
    }
  }
  
  export default reducer
  