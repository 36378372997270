import {
  CONTACTS_LOADING,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
} from "./actionTypes"

const initial_state = {
  contacts: [],
  loading: false,
  err: {},
}

const contacts = (state = initial_state, action) => {
  switch (action.type) {
    case CONTACTS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload,
        err: {},
      }
    case GET_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        contacts: [],
        err: action.payload,
      }
    default:
      return state
  }
}

export default contacts
