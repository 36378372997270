import {
  GET_FILES_FAILURE,
  GET_FILES_SUCCESS,
  FILES_LOADING,
} from "./actionTypes"

const initial_state = {
  files: [],
  loading: false,
  err: "",
}

const files = (state = initial_state, action) => {
  switch (action.type) {
    case FILES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload,
        err: "",
      }
    case GET_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        files: [],
        err: action.payload,
      }
    default:
      return state
  }
}

export default files
